<template>
  <div class="conectividadPublica">
        <!-- CONTENIDO -->
    <div class="container">
         <!-- BREADCRUM + LOGOTIPO + DATOS DE USUARIO -->
     <div class="row top-buffer">
        <div class="col-md-12">
          <ol class="breadcrumb">
            <li><a href="https://www.gob.mx"><i class="icon icon-home"></i></a></li>
            <li><a href="https://www.gob.mx/sct">SCT</a></li>
            <li>Portal Cobertura Universal</li>
            <li class="active">Programa de Conectividad en Sitios Públicos 2020-2021</li>
          </ol>
        </div>
      </div>
      <div class="row">
        <div class="col-md-7" id="logotipo">
          <img src="../assets/img/logo.png" alt="Secretaría de Comunicaciones y Transportes" />
        </div>
      </div>

      <!-- TÍTULO Y SUBTÍTULO -->
      <div class="row">
        <div class="col-md-12">
          <h1 style="margin-bottom:30px;">Programa de Conectividad en Sitios Públicos 2020-2021</h1>
          <hr class="red">
        </div>
      </div>

      <!-- CONTENIDO -->
      <div class="row">
        <div class="col-md-12">
          <p class="text-justify">El <strong>Programa de Conectividad en Sitios Públicos 2020-2021</strong> considera a las variables de conectividad significativa y asequibilidad, como elementos relevantes para la identificación de sitios públicos a
            conectar bajo la
            premisa que ambas son fundamentales para garantizar el artículo 6° constitucional a la población en México. El Objetivo General del PCSP 2020-2021 es:</p>
          <p class="text-justify"><strong>Contribuir al logro de la cobertura universal a través de la generación de la Base de Datos de los sitios públicos por conectar que se encuentren asociados a programas y proyectos públicos, y que presenten
              necesidades de conectividad social, principalmente en aquellos espacios ubicados dentro de las Localidades de Atención Prioritaria de Cobertura Social. Sus objetivos específicos son:</strong></p>
          <ol>
            <li class="text-justify"><strong>Priorizar la identificación de necesidades de conectividad de diversos programas y proyectos públicos prioritarios federales para fortalecer y coadyuvar en la prestación de los bienes y servicios que
                otorgan a la población en territorio nacional, en especial aquéllos que atiendan Localidades de Atención Prioritaria de Cobertura Social y bajo el esquema de Conectividad Significativa.</strong></li>
            <li class="text-justify"><strong>Determinar los elementos básicos a considerar en la detección de necesidades de conectividad significativa de diferentes actores sociales, principalmente de aquéllos ubicados en Localidades de Atención
                Prioritaria de Cobertura Social.</strong>
            </li>
            <li class="text-justify"><strong>Identificar nuevas soluciones sostenibles para conectar localidades rurales permitiendo la asequibilidad de los servicios.</strong></li>
          </ol>
          <p class="text-justify">En este sentido, el <strong>PCSP 2020-2021</strong> permite la identificación y localización de los sitios en todo el territorio nacional que presentan necesidades de conectividad social a fin de coadyuvar a que se
            les proporcione
            conexión gratuita a
            Internet a toda la población para habilitar su acceso a mejores opciones de educación, a la telemedicina, a los beneficios del gobierno electrónico y a otros servicios en línea que elevarán su calidad de vida. </p>
          <p class="text-justify">Te invitamos a descargar el archivo PDF para conocer los detalles del programa.</p>
          <a class="btn btn-default btn-sm xsmall-top-buffer" type="button" href="/pdf/Programa_de_Conectividad_de_Sitios_Publicos_2020-20212.pdf" download="Conectividad.pdf">Descargar archivo PDF</a>
          <a class="btn btn-default btn-sm xsmall-top-buffer" type="button" href="/pdf/sitios_publicos.ods" download="sitios_publicos.ods">CONSULTAR DATOS ABIERTOS</a>
        </div>
      </div>
     
       <div class="row">
        <div class="col-md-12 text-right">
          <hr />
          <a class="btn btn-default" type="button" href="/conectividad-sitios-publicosInter">Regresar</a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'conectividadPublica',
  data(){
    return{
      pdfData:''
    }
  },
 mounted(){
   
 },
  methods: {
    

  },
}
</script>

